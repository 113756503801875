import * as React from "react"

// import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/home/hero"
import Testimonial from "../components/home/testimonial"
import { graphql, Link, navigate } from "gatsby"
import { Disclosure } from "@headlessui/react"
import faqForReiki from "../data/faq-for-reiki"
import reikieBenefit from "../data/reikie-benefit"
import Grid from "../components/section/grid"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import Price from "../utils/Price"

export default function Page({ data, location }) {
  const wordFind = `Reiki`
  const bookASession = data.booking.nodes.filter(booking =>
    booking.frontmatter.title.includes(wordFind)
  )

  return (
    <Layout>
      <Seo title="Reiki" pathname={location.pathname} />
      <Hero
        title="Channelling Healing Energy"
        description="Balancing the physical, mental and spiritual body"
      />
      <section className="max-w-7xl mx-auto w-11/12 pt-12 pb-6 2xl:w-full flex flex-col gap-10">
        <div className="flex flex-col gap-4 items-center">
          <h3 className="text-4xl font-serif text-secondary">Reiki Benefits</h3>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {reikieBenefit.map((reiki, key) => (
            <div
              key={key}
              className="flex flex-col gap-4 bg-primary py-12 rounded"
            >
              <div className="flex flex-col items-center">{reiki.icon}</div>
              <div>
                <ul className="flex flex-col items-center gap-1">
                  {reiki.beneift.map((li, key) => (
                    <li key={key} className="text-sm font-light">
                      {li}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </section>
      <section className="max-w-7xl mx-auto w-11/12 py-6 2xl:w-full flex flex-col">
        <div className="flex flex-col gap-4 items-center">
          <h3 className="text-4xl font-serif text-secondary">What is Reiki?</h3>
        </div>
        <div>
          <Grid
            image={
              <StaticImage
                src="../images/reiki.png"
                className="h-full w-full object-cover"
                alt="reikie"
              />
            }
          >
            {/* <h3 className="text-2xl lg:text-4xl font-serif capitalize">
          Tony is an Evidential Medium
        </h3> */}
            <p className="text-stone-500 text-xs  lg:text-base">
              Reiki is a Japanese word that translates to “universal life
              energy.” It is not attached to any religion or specific belief
              system, but rather is a specific form of energy work designed to
              enhance healing.
            </p>
            <p className="text-stone-500 text-xs  lg:text-base">
              Reiki works to restore the body to its natural energy balance,
              which may have been interrupted or off-balanced as a result of
              stress, injury, trauma or sickness.
            </p>
            <p className="text-stone-500 text-xs  lg:text-base">
              Reiki treatment is a natural technique that anyone can enjoy and
              benefit from. Reiki can be used alongside other conventional or
              complementary treatments and even often helps to provide emotional
              support.
            </p>
          </Grid>
        </div>
      </section>

      <section className="max-w-7xl bg-primary mx-auto w-11/12 py-6 md:py-12 2xl:w-full flex flex-col gap-10">
        <div className="flex flex-col gap-4 items-center">
          <h3 className="text-4xl font-serif text-secondary">
            What to Expect During a Session
          </h3>
        </div>
        <div className="max-w-2xl text-center mx-auto">
          <p className="text-stone-500 text-xs  lg:text-base">
            The Reiki treatment lasts 30 or 60 minutes depending on your chosen
            duration. A conversation will take place before the session if there
            are any specific problems that you would like to address. Energy is
            not limited to time or space. I channel energy through my hands in a
            series of positions and crystals that represent your body and chakra
            system. Your entire body is treated with this energy, although we
            can also focus on specific challenged areas.
          </p>
        </div>
      </section>

      <section className="max-w-7xl mx-auto w-11/12 pt-6 2xl:w-full flex flex-col gap-10">
        <div className="flex flex-col gap-4 items-center">
          <h3 className="text-4xl font-serif text-secondary">About Tony</h3>
        </div>
        <div>
          <Grid
            reverse
            imageContainerClass="md:aspect-square"
            image={
              <StaticImage
                src="../images/tony.png"
                className="h-full w-full object-cover"
                alt="reikie"
              />
            }
          >
            <h3 className="text-xl lg:text-2xl font-serif capitalize">
              Tony Womersley is an English-born gifted medium, Reiki
              practitioner, entrepreneur, spiritual teacher and guide.
            </h3>
            <p className="text-stone-500 text-xs  lg:text-base">
              Before stepping into his life's purpose, Tony was an engineer,
              where he found himself unfulfilled in both his personal life and
              work. Mentally and physically, he experienced a personal rock
              bottom. In 2019 Tony had a massive spiritual awakening that
              changed his life forever. He was lying in bed on a Sunday night,
              he looked to the bedside table to see his deceased father's spirit
              in 3D form and his father was shining bright white. Tony then
              looked to the end of the bed where he saw 6 spirits in 3D form
              standing in a circle.
            </p>
            <p className="text-stone-500 text-xs  lg:text-base">
              Since that night, Tony’s been able to see spirit with his eyes
              open and through his third eye. Tony has been gifted with all the
              psychic senses, he can see, hear, feel and sense spirit.
            </p>
            <p className="text-stone-500 text-xs  lg:text-base">
              Tony now heals and guides others through the power of mediumship
              and energy healing to provide loving, supportive and life-changing
              guidance to others. He helps clients on their path of healing and
              personal growth.
            </p>
            <div>
              <Link
                to="/about-tony"
                className="px-6 py-2 bg-primary text-sm text-secondary"
              >
                Learn more
              </Link>
            </div>
          </Grid>
        </div>
      </section>

      <section className="max-w-7xl mx-auto w-11/12 pb-6 2xl:w-full flex flex-col gap-10">
        <div className="flex flex-col gap-4 items-center">
          <h3 className="text-4xl font-serif text-secondary">Book a Session</h3>
        </div>
        <div className="flex flex-col md:flex-row md:justify-center gap-4">
          {bookASession.map((item, key) => {
            const book = item.frontmatter
            return (
              item.frontmatter.publish && (
                <div key={key} className="flex-col bg-primary md:w-1/3">
                  <div className="h-64">
                    {book?.image?.childImageSharp ? (
                      <GatsbyImage
                        image={book.image.childImageSharp.gatsbyImageData}
                        alt={book.title || " "}
                        className="h-full w-full object-cover"
                      />
                    ) : (
                      <img
                        src={book.image}
                        alt={book.title || " "}
                        className="h-full w-full object-cover"
                      />
                    )}
                  </div>
                  <div className="p-2 flex flex-col gap-3">
                    <h3 className="text-xl font-serif text-secondary">
                      {book.title}
                    </h3>
                    <p className="text-sm font-light text-secondary/90">
                      {book.description}
                    </p>
                    <div className="flex justify-between items-center">
                      <span className="text-2xl font-serif ">
                        {Price(book.price)} /{" "}
                        <span className="text-sm">{book.duration} min</span>
                      </span>
                      <button
                        onClick={() =>
                          navigate(`/book-online${item.fields.slug}`)
                        }
                        className="px-4 py-2 bg-secondary text-primary text-xs font-light uppercase tracking-widest"
                      >
                        Book Now
                      </button>
                    </div>
                  </div>
                </div>
              )
            )
          })}
        </div>
      </section>

      <section className="max-w-7xl mx-auto w-11/12 py-6  2xl:w-full flex flex-col gap-10">
        <div className="flex flex-col gap-4 items-center">
          <h3 className="text-4xl font-serif text-secondary">
            Frequently Asked Questions
          </h3>
        </div>

        <ul className="flex flex-col gap-4 max-w-4xl w-full mx-auto">
          {faqForReiki.map((li, key) => (
            <Disclosure
              as="li"
              key={key}
              className="bg-primary rounded-md w-full flex flex-col items-start divide-y divide-secondary/20"
            >
              <Disclosure.Button className="p-4 w-full text-left flex justify-between items-center">
                <span className="font-semibold text-secondary">{li.title}</span>
                <span className="text-2xl">+</span>
              </Disclosure.Button>
              <Disclosure.Panel className="pl-4 md:pl-8 pr-4 py-4 w-full">
                <p>{li.description}</p>
              </Disclosure.Panel>
            </Disclosure>
          ))}
        </ul>
      </section>
      <Testimonial testimonials={data.testimonials.nodes} />
    </Layout>
  )
}

export const query = graphql`
  query {
    booking: allMarkdownRemark(
      filter: {
        fields: { collection: { eq: "booking" } }
        frontmatter: { publish: { eq: true } }
      }
      sort: { order: ASC, fields: frontmatter___title }
    ) {
      nodes {
        frontmatter {
          title
          publish
          image
          description
          price
          duration
        }
        fields {
          slug
        }
      }
    }

    testimonials: allMarkdownRemark(
      filter: {
        frontmatter: { publish: { eq: true } }
        fields: { collection: { eq: "testimonials" } }
      }
      sort: { fields: frontmatter___order }
    ) {
      nodes {
        frontmatter {
          title
          content
          country

          position
        }
      }
    }
  }
`
