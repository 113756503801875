import { StaticImage } from "gatsby-plugin-image"
import React from "react"

export default [
  {
    icon: (
      <StaticImage
        src="../images/reikie-icon.png"
        width={130}
        height={120}
        quality={100}
        className="h-20 w-20 shadow bg-white"
        objectFit="cover"
        objectPosition="-8.5px 0px"
        alt="re"
      />
    ),
    beneift: [
      "Relaxes the body and mind",
      "Increases energy",
      "Relieves stress and anxiety",
      "Promotes feelings of calmness",
    ],
  },
  {
    icon: (
      <StaticImage
        src="../images/reikie-icon.png"
        width={130}
        height={120}
        quality={100}
        className="h-20 w-20 shadow bg-white"
        objectFit="cover"
        objectPosition="-8.5px 0px"
        alt="re"
      />
    ),
    beneift: [
      "Encourages emotional release",
      "Eases pain and discomfort",
      "Promotes mental clarity",
      "Improves sleep",
    ],
  },
  {
    icon: (
      <StaticImage
        src="../images/reikie-icon.png"
        width={130}
        height={120}
        quality={100}
        className="h-20 w-20 shadow bg-white"
        objectFit="cover"
        objectPosition="-8.5px 0px"
        alt="re"
      />
    ),
    beneift: [
      "Increases speed of recovery from injury",
      "Eases muscle tension",
      "Enhances spiritual connection",
      "Removes blocked energy",
    ],
  },
]
