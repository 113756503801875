export default [
  {
    title: "What is Reiki?",
    description:
      "Reiki is a non-invasive technique to promote healing by releasing blockages from the energy centres (chakras) to enable a natural flow of energy in the body.",
  },
  {
    title: "Can Reiki be performed at a distance?",
    description:
      "Reiki is a powerful technique that allows you to send and receive Reiki beyond the constraints of space and time. This technique allows practitioners to transmit spiritual energy beyond physical contact.",
  },
  {
    title: "What happens during a reiki session?",
    description:
      "Once we've had our initial chat I will ask you to lay on your back and rest comfortably in a quiet space. You remain fully clothed while relaxing. Energy is not limited to time or space. I channel energy through my hands in a series of positions and crystals that represent your body and chakra system. Your entire body is treated with this energy, although we can also focus on specific challenged areas.",
  },
  {
    title: "What can I expect to feel during a reiki session?",
    description:
      "During the session, you might notice a warming sensation in certain areas. In other areas, you might not notice anything at all. Even if you come in with a particular problem area, you may experience a sense of relaxation that affects your whole body. You might even fall asleep during the session!",
  },
  {
    title: "How will I feel after a reiki session?",
    description:
      "Every person who tries reiki has a different experience. Your experience from session to session can vary as well. Many people report feeling a sense of calm in their bodies. Others report reduced pain or a clearer mind. Some people say they notice old symptoms resurfacing in a sort of “healing reaction,” but those symptoms pass quickly.",
  },
  {
    title: "Which conditions respond to reiki?",
    description:
      "Many people turn to reiki to relieve stress and anxiety. Practitioners also say that the therapy helps support the body’s natural healing mechanisms. Some healthcare professionals think that reiki helps reduce the side effects of drugs and radiation and may even reduce or eliminate the need for pain medications.",
  },
]
